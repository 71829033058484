import { Show } from "solid-js";
import { type RouteSectionProps, createAsync, A } from "@solidjs/router";
import { Title } from "@solidjs/meta";
import { MetaDetails } from "~/components/utility";
import Breadcrumb from "~/components/Breadcrumb";
import Body from "~/components/Body";

import { getBuilderContentById } from "~/utils/builder";

export default function CareerPosting(props: RouteSectionProps) {
  const content = createAsync(async () => {
    const data = await getBuilderContentById(props.params.slug, "jobs");
    return data;
  });

  return (
    <Show when={content()}>
      <Title>RomaMoulding.com - Jobs - {content()?.name}</Title>
      <MetaDetails
        title={`Careers - ${content()?.name}`}
        description="Apply now to join the Roma Team!"
        keywords="Jobs, Careers"
      />
      <Body>
        <Breadcrumb
          breadcrumbs={[
            { pageTitle: "Home", url: "/" },
            { pageTitle: "Careers", url: "/careers" },
            {
              pageTitle: content()?.name,
              url: `/jobs/${content()?.data?.slug}`,
            },
          ]}
        />
        <div class="mx-auto max-w-3xl flex flex-col gap-10 my-20 px-8">
          <div class="flex md:flex-row justify-between w-full pb-10 border-b">
            <div class="md:basis-3/4  flex flex-col gap-3 md:gap-8">
              <h2 class="text-3xl md:text-4xl font-bold">{content()?.name}</h2>
              <div class="flex flex-col md:flex-row text-sm w-full md:gap-5">
                <h4>
                  <span class="font-bold mr-2">Department:</span>
                  {content()?.data?.department}
                </h4>
                <h4>
                  <span class="font-bold mr-2">Reporting to:</span>
                  {content()?.data?.reportingTo}
                </h4>
                <h4>
                  <span class="font-bold mr-2">Location:</span>
                  {content()?.data?.location}
                </h4>
              </div>
            </div>
            <div class="md:basis-1/4 flex flex-col justify-between items-center">
              <Show
                when={content()?.data?.applyNowUrl.includes("mailto")}
                fallback={
                  <A
                    href={content()?.data?.applyNowUrl}
                    // href="https://docs.google.com/forms/d/e/1FAIpQLSdOhNnjBjRrGUmqYUEr_1WvbJnhHDDCapV2kqFKLlrx5pa7lA/viewform?vc=0&c=0&w=1&flr=0"
                    target="_blank"
                    rel="noreferrer noopener"
                    class="text-white bg-black text-center py-2 px-8 rounded-full whitespace-nowrap"
                  >
                    Apply now
                  </A>
                }
              >
                <a
                  href={content()?.data?.applyNowUrl}
                  class="text-white bg-black text-center py-2 px-8 rounded-full whitespace-nowrap"
                >
                  Apply Now
                </a>
              </Show>
              {/* ?? No Share functionality */}
              {/* <a href="#" class="">
                  <span class="mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width={2}
                      stroke="#0079D1"
                      class="w-4 h-4 inline-block"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                      />
                    </svg>
                  </span>
                  <p class="inline">Share this job</p>
                </a> */}
            </div>
          </div>
          <section
            innerHTML={content()?.data?.content}
            class="list-inside list-disc"
            style={{ "list-style-type": "disc" }}
          />
          <p class="text-xs text-gray-400 leading-6 border-t pt-10">
            The above statements are intended to describe the general nature and
            level of work being performed by people assigned to this position.
            They are not intended to be an exhaustive list of all
            responsibilities, duties and skills required of associates so
            classified. The Company is committed to providing equal opportunity
            in all employment practices without regard to age, race, color,
            national origin, sex, sexual orientation, religion, physical or
            mental disability, or any other category protected by law. As part
            of this commitment, the Company shall provide reasonable
            accommodations of known disabilities to enable an applicant or
            employee to apply for employment, perform the essential functions of
            the job, or enjoy the benefits and privileges of employment as
            required by the law.
          </p>
        </div>
      </Body>
    </Show>
  );
}
